<template>
  <div>
    <v-dialog v-model="dialog" persistent max-width="450">
      <template v-slot:activator="{ on, attrs }">
        <div class="navi-link" v-bind="attrs" v-on="on">
          <span class="navi-icon">
            <i class="flaticon-edit-1 text-warning"></i>
          </span>
          <span class="navi-text">{{ $t('ACTION.UPDATE') }} </span>
        </div>
      </template>
      <v-card>
        <v-card-title class="headline"
          >Nomenklatura Guruh ozgartirish</v-card-title
        >
        <v-card-text>
          <v-text-field
            outlined
            dense
            label="Nomi"
            v-model="getData.name"
          ></v-text-field>
          <v-text-field
            outlined
            dense
            label="Kod"
            maxLength="4"
            minLength="4"
            v-model="getData.code"
          ></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="dialog = false"
            >Bekor qilish</v-btn
          >
          <v-btn color="green darken-1" text @click="submit(obj.id)"
            >Yuborish</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data: () => ({
    dialog: false,
    nameInput: '',
    codeInput: ''
  }),
  methods: {
    submit(value) {
      const id = {
        id: value
      }
      const data = {
        name: this.getData.name,
        code: this.getData.code
      }
      this.$store.dispatch('updateNomenklaturaGroup', { data, id })
      this.dialog = false
    }
  },
  computed: {
    getData() {
      const data = {
        name: this.obj.name,
        code: this.obj.code
      }
      return data
    }
  },
  props: {
    obj: Object
  }
}
</script>

<style></style>
