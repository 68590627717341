<template>
  <div>
    <v-row justify="center">
      <v-dialog v-model="dialog" persistent max-width="450">
        <template v-slot:activator="{ on, attrs }">
          <v-btn color="primary" small dark v-bind="attrs" v-on="on">
            {{ $t('MENU.ENTERPRICE.STORE.NOMENCLATURE_GROUP') }} +
          </v-btn>
        </template>
        <v-card>
          <v-card-title class="headline"
            >Nomenklatura Guruh qoshish</v-card-title
          >
          <v-card-text>
            <v-text-field
              outlined
              dense
              label="Nomi"
              v-model="nameInput"
            ></v-text-field>
            <v-text-field
              outlined
              dense
              label="Kod"
              maxLength="4"
              minLength="4"
              v-model="codeInput"
            ></v-text-field>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              :disabled="newnomgroupLoading"
              color="green darken-1"
              text
              @click="dialog = false"
              >Bekor qilish</v-btn
            >
            <v-btn
              :disabled="newnomgroupLoading"
              color="green darken-1"
              text
              @click="submit()"
              ><i v-if="newnomgroupLoading" class="el-icon-loading"></i>
              Yuborish</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
export default {
  data: () => ({
    newnomgroupLoading: false,
    dialog: false,
    nameInput: '',
    codeInput: ''
  }),
  methods: {
    submit() {
      const data = {
        name: this.nameInput,
        code: this.codeInput
      }
      this.newnomgroupLoading = true
      this.$store
        .dispatch('createNomenklaturaGroup', data)
        .then((res) => {
          console.log(res)
          this.newnomgroupLoading = false
          this.nameInput = ''
          this.codeInput = ''
          this.dialog = false
        })
        .catch((err) => {
          this.newnomgroupLoading = false
          console.error(err)
        })
    }
  }
}
</script>

<style></style>
