<template>
  <div>
    <div>
      <div>
        <div class="row">
          <div class="col-12">
            <div class="card card-custom gutter-b example example-compact">
              <div
                class="card-header d-flex align-items-center justify-content-between"
              >
                <div class="card-title">
                  <h3 class="card-label">
                    {{ $t('MENU.ENTERPRICE.STORE.NOMENCLATURE_GROUP') }}
                  </h3>
                </div>
                <div class="card-button">
                  <create />
                </div>
              </div>
              <div>
              </div>
              <div class="card-body">
                <v-data-table
                  :headers="headers"
                  :items="getNomenklaturaGroup"
                  :page.sync="page"
                  :items-per-page="10"
                  single-select
                  @click:row="rowClick"
                  item-key="id"
                  hide-default-footer
                  class="elevation-1 row-pointer"
                >
                  <template v-slot:[`item.action`]="{ item }">
                    <div style="text-align: right !important">
                      <action :index="item"></action>
                    </div>
                  </template>
                </v-data-table>
                <div class="text-center">
                  <v-pagination
                    v-model="page"
                    :length="Math.ceil(getNomenklaturaGroup.length / 10)"
                  ></v-pagination>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import action from './action'
import create from './create'
export default {
  data() {
    return {
      counter: 0, // count the clicks
      timer: null, // Needs to be specified here so it can be accessed on both clicks
      selectedId: -1,
      page: 1
    }
  },
  computed: {
    headers() {
      return [
        {
          text: '#',
          value: 'id'
        },
        {
          text: this.$t('TABLE_HEADER.NAME'),
          value: 'name'
        },

        {
          text: this.$t('TABLE_HEADER.CODE'),
          value: 'code'
        },
        {
          text: '',
          value: 'action',
          sortable: false
        }
      ]
    },
    getNomenklaturaGroup() {
      return this.$store.state.requests.nomenklaturaGroup || []
    }
  },
  methods: {
    rowClick: function (item, row) {
      var self = this
      this.counter++
      if (this.counter == 1) {
        this.timer = setTimeout(function () {
          self.counter = 0
          row.select(true)
          this.selectedId = item.id
        }, 200)
      } else {
        clearTimeout(this.timer)
        self.counter = 0
        row.select(false)
      }
    }
  },
  mounted() {
    this.$store.dispatch('getNomenklaturaGroup')

    this.$nextTick(() => {
      this.$store.dispatch(SET_BREADCRUMB, [
        { title: this.$t('MENU.ENTERPRICE.TITLE') },
        { title: this.$t('MENU.ENTERPRICE.STORE.TITLE') },

        { title: this.$t('MENU.ENTERPRICE.STORE.NOMENCLATURE_GROUP') }
      ])
    })
  },
  components: {
    action,
    create
  },
}
</script>

<style scoped>
.v-application .elevation-1 {
  box-shadow: none !important;
}

.v-data-table /deep/ .v-data-table__wrapper > table > thead > tr > th {
  font-size: 1rem !important;
  color: #000 !important;
  font-weight: 500 !important;
}
.v-data-table /deep/ .v-data-table__wrapper > table > tbody > tr > td {
  font-size: 1rem !important;
  color: #000 !important;
  height: 60px !important;
}
.v-data-table /deep/ .v-data-table__wrapper {
  overflow-x: scroll !important;
  white-space: nowrap !important;
  width: 100%;
}
</style>
